/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import sms from "assets/images/sms.png";
import mail from "assets/images/mail.png";
import chat from "assets/images/chat.png";
import whastapp from "assets/images/whatsapp.png";

export default [
  {
    image: sms,
    name: "SMS",
    description: "Integrate SMS to your application in few clicks",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "contact",
      disabled: true
    },
  },
  {
    image: mail,
    name: "EMail",
    description: "Integrate EMail to your application in few clicks",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "contact",
      disabled: true
    },
  },
  {
    image: chat,
    name: "Chats",
    description: "Integrating seamless chatting to your application with ease",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      label: "contact",
      disabled: true
    },
  },
  {
    image: whastapp,
    name: "Whatsapp",
    description: "Integrate whatsapp with your application",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      label: "contact",
      disabled: true
    },
  }
];
