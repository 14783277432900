/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// React components
import MDBox from "components/MDBox";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { getDashboardDetails } from "network/service";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/material";
import Lottie from 'react-lottie';
import loader from 'assets/lottie/loader.json';
import { useLoading } from 'context/loading.context';


function Dashboard() {
  const { loading, showLoader, hideLoader } = useLoading();
  const { sales, tasks } = reportsLineChartData;
  const [load, setLoad] = useState(false)
  const [data, setData] = useState({});

  const defaultOptions = {
    loop: true, // Set to true if you want the animation to loop
    autoplay: true, // Set to true to start the animation when the component mounts
    animationData: loader, // The animation data
  }



  const fetchDashboardDetails = () => {
    setLoad(true);
    showLoader();
    getDashboardDetails().then(async (resp) => {
      console.log(resp.data);
      setData(resp.data)
      if (data != {}) {
        setLoad(false);
        hideLoader();
      }
      else {
        setLoad(true);
        showLoader();
      }

    }).catch((err) => {
      console.log(err);
      setLoad(false);
      hideLoader();
    });

  }

  useEffect(() => {
    fetchDashboardDetails()

  }, [setData, setLoad]);

  return (<DashboardLayout>
    <DashboardNavbar />
    <MDBox py={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              icon="leaderboard"
              title="Total Projects"
              count={data.totalprojects != undefined || "" ? data.totalprojects : "0"}
              percentage={{ color: "success", amount: "3", label: "projects are online" }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              icon="upload"
              title="Total API Calls"
              count={data.totalcalls != undefined || "" ? data.totalcalls : "0"}
              percentage={{
                color: "error",
                amount: "-3%",
                label: "than yesterday",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="success"
              icon="download"
              title="Data Transfer"
              count={data.totaltransfer != undefined || 0 ? (data.totaltransfer / 1024).toPrecision(2) + " kb" : "0 kb"}
              percentage={{
                color: "success",
                amount: "+1%",
                label: "than yesterday",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="primary"
              icon="person_add"
              title="Credit"
              count={data.credits != undefined || "" ? data.credits : "0"}
              percentage={{
                color: "success",
                amount: "",
                label: "Just updated",
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="memory consumption"
                description="RAM Utilization"
                date="just updated"
                chart={reportsBarChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="success"
                title="daily api hits"
                description={
                  <>
                    (<strong>+15%</strong>) increase in today calls.
                  </>
                }
                date="just updated"
                chart={sales}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="resource utilisation"
                description="Processing and Transfer Usage"
                date="just updated"
                chart={tasks}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Projects />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OrdersOverview />
              </Grid>
            </Grid>
          </MDBox> */}
    </MDBox>

  </DashboardLayout>
  );
}

export default Dashboard;
