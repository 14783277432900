/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import LinearProgress from '@mui/material/LinearProgress';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { NavLink, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, GoogleAuthProvider, getAuth, signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import { auth } from "../../../firebase"
import { fetchLogin, validateUser } from "network/service";
import { AuthContext } from "../../../context/auth.context"

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/web_ai.jpg";
import { verifyMobile } from "network/service";

function Basic() {
  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false);
  const [code, setCode] = useState("");
  const { setLogin, setUserDoc } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [screen, setScreen] = useState(0);
  const [mobile, setMobile] = useState("");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      }
    });
  }

  const handleSend = (phone) => {
    // generateRecaptcha();
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      }
    });
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("Verificationn code sent to the mobile number");
        setScreen(1);
        setLoading(false);
      }).catch((error) => {
        // Error; SMS not sent
        console.log(error);
        setLoading(false);
      });
  }

  const verifyOtp = () => {
    let otp = code;
    setLoading(true);
    if (otp.length === 6) {
      // verify otp
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        let user = result.user;
        console.log(user);
        user.getIdToken().then(function (idToken) {
          console.log(idToken);
          verifyMobile({
            idToken: idToken,
          }).then((resp) => {
            console.log(resp.data);
            //Mobile Verification Successfull
            setScreen(0);
            signOut(auth).then(() => {
              // Sign-out successful.
              console.log("Signed out successfully");
              emailPassLogin();
            }).catch((error) => {
              // An error happened.
              setLoading(false);
            });
          }).catch((err) => {
            console.log(err);
            setLoading(false);
            alert('Something not right. Please try again after sometime');
          })
        });
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        setLoading(false);
        alert('User couldn\'t sign in (bad verification code?)');
      });
    }
  }

  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {
      setLoading(true);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      user.getIdToken().then(function (idToken) {
        console.log(idToken);
        fetchLogin(idToken)
          .then((resp) => {
            console.log(resp.data);
            window.localStorage.setItem("jwt", resp.data.token);
            setLogin(resp.data.token);
            setUserDoc(resp.data);
            console.log("User Logged in", window.localStorage.getItem("jwt"));
            setLoading(false);
            navigate("/dashboard");
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      });
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(email);
      console.log(credential);
    });
  }

  const emailPassLogin = async () => {
    // return new Promise((res, rej) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        user.getIdToken().then(function (idToken) {
          console.log(idToken);
          fetchLogin(idToken)
            .then((resp) => {
              console.log(resp.data);
              window.localStorage.setItem("jwt", resp.data.token);
              setLogin(resp.data.token);
              setUserDoc(resp.data);
              console.log("User Logged in", window.localStorage.getItem("jwt"));
              setLoading(false);
              navigate("/dashboard");
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
        // alert(error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        alert(errorMessage);
      });
    // });
  }

  const onLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    validateUser({ email: email }).then((resp) => {
      console.log(resp.data);
      if (resp.data.status == 2) {
        //Need to validate mobile number
        setMobile(resp.data.mobile);
        handleSend(resp.data.mobile);
      }
      else if (resp.data.status == 1) {
        //Mobile number already verified
        emailPassLogin();
        // setLoading(false);
      }
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      alert(err.response.data.errors[0].message);
    })

    // signInWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user;
    //     console.log(user);
    //     user.getIdToken().then(function (idToken) {
    //       console.log(idToken);
    //       fetchLogin(idToken)
    //         .then((resp) => {
    //           console.log(resp.data);
    //           window.localStorage.setItem("jwt", resp.data.token);
    //           setLogin(resp.data.token);
    //           setUserDoc(resp.data);
    //           console.log("User Logged in", window.localStorage.getItem("jwt"));
    //           setLoading(false);
    //           navigate("/dashboard");
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //           console.log(err);
    //         });
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     alert(error.code);
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log(errorCode, errorMessage)
    //   });

  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
         {/*  <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        {
          (screen == 0) &&
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(text) => {
                    setEmail(text.target.value)
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(text) => {
                    setPassword(text.target.value)
                  }}
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton disabled={isLoading} variant="gradient" color="info" onClick={onLogin} fullWidth id={'sign-in-button'}>
                  sign in
                </MDButton>
              </MDBox>
              {/* <MDBox mt={4} mb={1}>
                <MDButton disabled={isLoading} variant="outlined" color="info" startIcon={<GoogleIcon color="inherit" />} onClick={() => { signInWithGoogle() }} fullWidth>
                  Google
                </MDButton>
              </MDBox> */}
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            {isLoading && <MDBox style={{ overflow: 'hidden' }}><LinearProgress /></MDBox>}
          </MDBox>
        }
        {
          (screen == 1) &&
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  label="Code"
                  fullWidth
                  value={code}
                  onChange={(text) => {
                    setCode(text.target.value)
                  }}
                  error={(code.length != 6)}
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton disabled={(isLoading) || (!(code.length == 6))} variant="gradient" color="info" onClick={verifyOtp} fullWidth>
                  verify
                </MDButton>
              </MDBox>
              {/* <MDBox mt={4} mb={1}>
                <MDButton disabled={isLoading} variant="outlined" color="info" startIcon={<GoogleIcon color="inherit" />} onClick={() => { signInWithGoogle() }} fullWidth>
                  Google
                </MDButton>
              </MDBox> */}
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {`OTP has been sent to your mobile ${mobile}. In case of help please contact us.`}
                  {/* <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography> */}
                </MDTypography>
              </MDBox>
            </MDBox>
            {isLoading && <MDBox style={{ overflow: 'hidden' }}><LinearProgress /></MDBox>}
          </MDBox>
        }
      </Card>
    </BasicLayout>
  );
}

export default Basic;
