/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import AddonsList from "examples/Lists/AddonList";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import addonListData from "layouts/profile/data/addonListData";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { getProjectDetails } from "network/service";
import { useLoading } from 'context/loading.context';
import { getProfileDetails } from "network/service";



function Overview() {
  const { loading, showLoader, hideLoader } = useLoading();
  const navigate = useNavigate();
  const [load, setLoading] = useState(true);
  const [data, setData] = useState({});


  const fetchProfileDetails = () => {
    setLoading(true);
    showLoader();
    getProfileDetails().then(async (resp) => {
      console.log(resp.data);
      setData(resp.data)
      if (data != {}) {
        setLoading(false);
        hideLoader();
      }
      else {
        setLoading(true);
        showLoader();
      }
    }).catch((err) => {
      setLoading(false);
      hideLoader();
      console.log(err);
    });
  }

  useEffect(() => {
    fetchProfileDetails();
  }, [setLoading, setData])

  const details = {
    name: `${data.email}`,
    profession: ""
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header details={details}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid style={{ opacity: 0.2, pointerEvents: "none" }} item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={8} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description=""
                info={{
                  fullName: "pop",
                  mobile: `${data.mobile}`,
                  email: `${data.email}`,
                  location: "INDIA",
                }}
                social={[
                  // {
                  //   link: "https://www.facebook.com/CreativeTim/",
                  //   icon: <FacebookIcon />,
                  //   color: "facebook",
                  // },
                  // {
                  //   link: "https://twitter.com/creativetim",
                  //   icon: <TwitterIcon />,
                  //   color: "twitter",
                  // },
                  // {
                  //   link: "https://www.instagram.com/creativetimofficial/",
                  //   icon: <InstagramIcon />,
                  //   color: "instagram",
                  // },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="plan information"
                description=""
                info={{
                  planName: `${data.plan?.name}`,
                  apiCalls: `${data.plan?.api_calls_day}/day`,
                  dataTransfer: `${data.plan?.data_transfer_day}/day`,
                  memoryLimit: `${data.plan?.memory} mb`,
                  projectLimit: `${data.plan?.projects}`,
                  entitiesLimit: `${data.plan?.entities}`,
                  customFunctions: `${data.plan?.custom_functions}`,
                  deviceFuntions: `${data.plan?.device_functions}`
                }}
                social={[

                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

          </Grid>
          <Grid item xs={12} xl={4}>
            <AddonsList title="Add-Ons" profiles={addonListData} shadow={false} />
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
      </Header>

    </DashboardLayout>
  );
}

export default Overview;
