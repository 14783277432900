// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoWStmHRqBxxvuZktVSCtSooNdYgpIx-Q",
  authDomain: "test-c93c4.firebaseapp.com",
  projectId: "test-c93c4",
  storageBucket: "test-c93c4.appspot.com",
  messagingSenderId: "520167586561",
  appId: "1:520167586561:web:1a33bfbd2b49d89726f900",
  measurementId: "G-4K9NYEWR18"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export default app;