import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import Tooltip from "@mui/material/Tooltip";
import MDProgress from "components/MDProgress";
import Switch from "@mui/material/Switch";
import SettingsIcon from '@mui/icons-material/Settings';
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { NavLink, useNavigate } from 'react-router-dom';


export default function data(projects, editCall, setDeployState) {

    const navigate = useNavigate();
    const avatars = (members) =>
        members.map(([image, name]) => (
            <Tooltip key={name} title={name} placeholder="bottom">
                <MDAvatar
                    src={image}
                    alt="name"
                    size="xs"
                    sx={{
                        border: ({ borders: { borderWidth }, palette: { white } }) =>
                            `${borderWidth[2]} solid ${white.main}`,
                        cursor: "pointer",
                        position: "relative",
                        "&:not(:first-of-type)": {
                            ml: -1.25,
                        },
                        "&:hover, &:focus": {
                            zIndex: "10",
                        },
                    }}
                />
            </Tooltip>
        ));

    const Project = ({ image, name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                {name.toUpperCase()}
            </MDTypography>
        </MDBox>
    );

    const Progress = ({ color, value }) => (
        <MDBox display="flex" alignItems="center">
            <MDTypography variant="caption" color="text" fontWeight="medium">
                {value}%
            </MDTypography>
            <MDBox ml={0.5} width="9rem">
                <MDProgress variant="gradient" color={color} value={value} />
            </MDBox>
        </MDBox>
    );

    const rederList = () => {
        let rows = [];
        for (let ele of projects) {
            console.log("Deployment status", ele);
            rows.push(
                {
                    project: <Project image={ele.logo} name={ele.name} />,
                    members: (
                        <MDBox display="flex" py={1}>
                            {avatars(ele.members)}
                        </MDBox>
                    ),
                    budget: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {ele.budget}
                        </MDTypography>
                    ),
                    completion: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            5
                        </MDTypography>
                    ),
                    status: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={(ele.deployment == 1) ? "Deployed" : (ele.deployment == 2) ? "Failed" : "Queued"} color={(ele.deployment == 1) ? "success" : (ele.deployment == 2) ? "error" : "info"} variant="gradient" size="sm" />
                        </MDBox>
                    ),
                    state: (
                        <MDBox mt={0.5}>
                            <Switch disabled={(ele.deployment == 0) ? false : false} checked={(ele.deployment == 1) ? true : false} onChange={() => setDeployState((ele.deployment == 1) ? false : true , ele.id)} />
                        </MDBox>
                    ),

                    settings: (

                        <MDButton
                            color="info"
                            variant="outlined"
                            iconOnly
                            onClick={() => {
                                navigate("/project", {state:{id: ele.id}})
                            }}
                        >
                            <Icon>settings</Icon>
                        </MDButton>
                    ),
                }
            );
        }
        return rows;
    }

    return {
        columns: [
            { Header: "project", accessor: "project", align: "left" },
            // { Header: "members", accessor: "members", width: "10%", align: "left" },
            // { Header: "budget", accessor: "budget", align: "center" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "state", accessor: "state", align: "center" },
            { Header: "API Hits", accessor: "completion", align: "center" },
            { Header: "settings", accessor: "settings", align: "center" },
        ],

        rows: rederList(),
    };
}
