import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ProjectStepper from "layouts/stepper";
import Projects from "layouts/projects";
import Project from "layouts/project";

// @mui icons
import Icon from "@mui/material/Icon";
import LogComponent from "layouts/logging";
import MobileCreation from "layouts/mobile";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="small">dvr</Icon>,
    route: "/projects",
    component: <Projects />,
  },
  {
    type: "collapse",
    name: "Logs",
    key: "logs",
    icon: <Icon fontSize="small">terminal</Icon>,
    route: "/logs",
    component: <LogComponent />,
  },
  {
    type: "collapse",
    name: "Project Stepper",
    key: "project-stepper",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/create-project",
    component: <ProjectStepper />,
  },
  {
    type: "collapse",
    name: "Documentation",
    key: "documentation",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/documentation",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Mobile App",
    key: "mobile",
    icon: <Icon fontSize="small">smartphone</Icon>,
    route: "/mobile",
    component: <MobileCreation />,
  },
  {
    type: "hidden",
    name: "Project",
    key: "project",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/project",
    component: <Project />,
  },
];

export const un_auth_routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
]

export default routes;
