/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React from 'react';

import { useContext, useEffect, useState } from "react";
// React components
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDTypography from "components/MDTypography";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MDInput from "components/MDInput";
import Bill from "layouts/billing/components/Bill";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Dialog from '@mui/material/Dialog';
import MDSnackbar from "components/MDSnackbar";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Check } from "@mui/icons-material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ArrowDropDown } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from "@mui/material/Autocomplete";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Chip, CardMedia, CardContent, CardActionArea } from "@mui/material";
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createProject, checkConnection, editProject, deployProject } from "network/service";
import { useLoading } from 'context/loading.context';
import { NavLink, useNavigate } from 'react-router-dom';


const steps = ["Create Project", "Connect Database", "Create Entity"]

function ProjectStepper() {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [enableUserAuth, setEnableUserAuth] = useState(false);
    const [enableDeviceManager, setEnableDeviceManager] = useState(false);
    const [age, setAge] = React.useState('');
    const [open, setOpenEntity] = useState(false);
    const [openField, setOpenField] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [projectID, setProjectId] = useState("");
    const [projectName, setProjectName] = useState("")
    const [projDescription, setProjectDescription] = useState("");
    const [mongoConnectionUrl, setMongoConnectionUrl] = useState("");
    const [enableUserAuthentication, setEnableUserAuthentication] = useState(false);
    const [collectionName, setCollectionName] = useState("");
    const { loading, showLoader, hideLoader } = useLoading();

    const [userAuthUsername, setUserAuthUsername] = useState("");
    const [userAuthPassword, setUserAuthPassword] = useState("");
    const [mqttCreds, setMqttCreds] = useState({
        url: "",
        clientId: "",
        username: "",
        password: ""
    });
    const [entity, setEntity] = useState("")
    const [entities, setEntities] = useState();
    const [fields, setFields] = useState();
    const [mongoConnectionStatus, setMongoConnectionStatus] = useState(false);
    const [currentOpenFieldInputPopup, setCurrentOpenFieldInputPopup] = useState()
    const [currentOpenFieldInputItem, setCurrentOpenFieldInputItem] = useState()
    const [currentOpenFieldInputItemName, setCurrentOpenFieldInputItemName] = useState()
    const [snackBarColor, setSnackBarColor] = useState("aweXyVZBqF00vuWWLHOC");
    const [snackBarIcon, setSnackBarIcon] = useState("aweXyVZBqF00vuWWLHOC");
    const [snackBarTitle, setSnackBarTitle] = useState("aweXyVZBqF00vuWWLHOC");
    const [snackBarContent, setSnackBarContent] = useState("aweXyVZBqF00vuWWLHOC");
    const toggleSnackbar = () => setOpenSnackBar(!openSnackBar);

    const [config, setConfig] = useState({
        "id": projectID,
        "url": mongoConnectionUrl,
        "database": collectionName,
        "configurations": [
            {
                "name": "parcel_code",
                "value": 1
            }
        ],
        "authEnabled": enableUserAuth,
        ...(enableUserAuth && {
            "admin": {
                "username": userAuthUsername,
                "password": userAuthPassword
            },
        }),
        "fileupload_path": "uploads",
        "base": `/${projectID}`,
        properties: [],
    });


    /*   useEffect(() => {
         console.log(config)
        }); */

    /*  const projId = "aweXyVZBqF00vuWWLHOC" */

    const handleClickOpenEntity = () => {
        setOpenEntity(true);
    };

    const handleCloseEntity = () => {
        setOpenEntity(false);
    };

    const handleClickOpenField = (index, item) => {
        setCurrentOpenFieldInputPopup(index);
        setCurrentOpenFieldInputItem(item);
        setCurrentOpenFieldInputItemName(item.name);
        setOpenField(true);
    };


    const checkConnect = () => {
        showLoader();
        checkConnection(mongoConnectionUrl)
            .then((resp) => {
                hideLoader();
                console.log(resp.data, "test abi");
                if (resp.data.message === "success") {
                    setMongoConnectionStatus(true);
                    setSnackBarColor("success")
                    setSnackBarIcon("notifications")
                    setSnackBarContent("MongoDB is Connected ")
                    setSnackBarTitle("Mongo Connection Success")
                    setOpenSnackBar(true);
                }
                else {
                    setSnackBarColor("error")
                    setSnackBarIcon("error")
                    setSnackBarContent("Please Check MongoDB URL")
                    setSnackBarTitle("Mongo Connection falied")
                    setOpenSnackBar(true);
                }

            })
            .catch((err) => {
                hideLoader();
                console.log(err);
            });
    };

    const handleCloseField = () => {
        setOpenField(false);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const opeSnackBar = (color, icon, message, title) => {
        setSnackBarColor(color)
        setSnackBarIcon(icon)
        setSnackBarContent(message)
        setSnackBarTitle(title)
        setOpenSnackBar(true);
    }

    const handleNext = () => {
        console.log(activeStep);
        let newSkipped = skipped;

        if (activeStep == 0) {
            if (projectID != "") {
                console.log("edit project start");
                showLoader();
                editProject(projectID, projectName, projDescription)
                    .then((resp) => {
                        console.log("edit project sucess");
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        hideLoader();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.response.data.message)
                        hideLoader();
                    });

            }
            else {
                showLoader();
                createProject(projectName, projDescription)
                    .then((resp) => {
                        hideLoader();
                        console.log("new project");
                        console.log(resp.data, "test abi");
                        setProjectId(resp.data.id);
                        console.log(projectID, "test id")
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    })
                    .catch((err) => {
                        hideLoader();
                        console.log("kkkkkkkkkkkkk");
                        alert(err.response.data.message);
                        console.log(err);
                    });
            }

        }

        else {

            if (activeStep == 1) {
                if (mongoConnectionStatus) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                else {
                    setSnackBarColor("error")
                    setSnackBarIcon("error")
                    setSnackBarContent("please connect Mongo DB")
                    setSnackBarTitle("Connect Mongo DB")
                    setOpenSnackBar(true);
                }
            }
            else {
                console.log(projectID, mongoConnectionUrl, "test step")
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }


        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCreate = () => {
        showLoader();
        let temp_config = {
            "id": projectID,
            "url": mongoConnectionUrl,
            "database": collectionName,
            "configurations": [

            ],
            "authEnabled": enableUserAuth,
            ...(enableUserAuth && {
                "admin": {
                    "username": userAuthUsername,
                    "password": userAuthPassword
                },
            }),
            ...(enableDeviceManager && {
                "deviceManager": {
                    protocol: "mqtt",
                    config: mqttCreds
                }
            }),
            "fileupload_path": "uploads",
            "base": `/${projectID}`,
            properties: [...config.properties],
        };

        setConfig({ ...temp_config });

        // setConfig((prevConfig) => ({
        //     "id": projectID,
        //     "url": mongoConnectionUrl,
        //     "database": collectionName,
        //     "configurations": [
        //         {
        //             "name": "parcel_code",
        //             "value": 1
        //         }
        //     ],
        //     "authEnabled": enableUserAuth,
        //     ...(enableUserAuth && {
        //         "admin": {
        //             "username": userAuthUsername,
        //             "password": userAuthPassword
        //         },
        //     }),
        //     "fileupload_path": "uploads",
        //     "base": `/${projectID}`,
        //     properties: [...prevConfig.properties],
        // }));
        console.log(temp_config, projectID, "handle create");

        deployProject(projectID, temp_config).then((resp) => {
            hideLoader();
            console.log(resp.data, "test abi");
            navigate("/projects");
        }).catch((err) => {
            hideLoader();
            console.log(err);
        });
    };

    const handleEntityCreate = () => {
        var temp = [];
        for (let i = 0; i < config.properties.length; i++) {
            temp.push(config.properties[i].name)

        }

        if (temp.includes(entity)) {
            setSnackBarColor("error")
            setSnackBarIcon("error")
            setSnackBarContent("please enter valid entity name")
            setSnackBarTitle("Entity name already exist")
            setOpenSnackBar(true);
            console.log("Entity already found");
        }
        else {
            const entitiesTmp = [{
                "name": `${entity}`,
                "fields": []
            }]

            setConfig((prevConfig) => ({
                ...prevConfig,
                properties: [...prevConfig.properties, ...entitiesTmp],
            }));

            setEntity('')

            console.log(config);

            setOpenEntity(false);
        }


    }


    const SnackBarField = (props) => {
        return (<MDSnackbar
            color={props.color}
            icon={props.icon}
            title={props.title}
            content={props.content}
            open={openSnackBar}
            close={toggleSnackbar}
        />)
    }

    const FieldDialogBox = (props) => {

        const [fieldName, setFieldName] = useState("");
        const [fieldType, setFieldType] = useState("");
        const [fieldRelation, setFieldRelation] = useState(null);
        const [fieldDefaultValue, setFieldDefaultValue] = useState();
        const [fieldOneToOne, setFieldOneToOne] = useState();
        const [fieldCanNull, setFieldCanNull] = useState();
        const [fieldUnique, setFieldUnique] = useState();
        const [snackProps, setSnackProps] = useState({ show: true, color: "error", icon: "error", message: "please enter valid field name", title: "Field name already exist" });




        function submitFieldDetails() {

            const fieldTmp = {
                "name": fieldName,
                "type": fieldType,
                "default": fieldDefaultValue,
                "nullable": fieldCanNull,
                "relation": fieldRelation ? fieldRelation.name : '',
                "one_to_one": fieldOneToOne,
                "unique": fieldUnique
            }
            props.createCallback(fieldTmp);

        }



        return (<Dialog open={props.show} onClose={props.handleClose}>
            <DialogTitle>Input fields for "{props.title}" Entity:</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    alignContent="stretch"
                    wrap="wrap"
                    style={{ marginTop: "8px" }}
                >
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "2px" }}
                    >
                        <Grid item spacing={0}>
                            <MDInput
                                label="Field Name"
                                value={fieldName}
                                onChange={(e) => {
                                    setFieldName(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    label="Type"
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={fieldType}
                                    onChange={(e) => {
                                        setFieldType(e.target.value)
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={"string"}>String</MenuItem>
                                    <MenuItem value={"number"}>Numeric</MenuItem>
                                    {/* <MenuItem value={2}>Timestamp</MenuItem> */}
                                    <MenuItem value={"relation"}>Relation</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    spacing={1}
                    style={{ marginTop: "2px" }}
                >
                    {(fieldType !== "relation") &&
                        <Grid item spacing={0}>
                            <MDInput
                                value={fieldDefaultValue}
                                onChange={(e) => {
                                    setFieldDefaultValue(e.target.value)
                                }}
                                label="Default Value"
                            />
                        </Grid>
                    }

                    {(fieldType === "relation") &&
                        <Grid item spacing={0}>
                            <Autocomplete
                                label="Entity"
                                disableClearable
                                value={fieldRelation}
                                options={props.getEntityNames(props.title)}
                                onChange={(event, newValue) => {
                                    setFieldRelation(newValue);
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <MDInput label="Entity" {...params} />}
                            />
                        </Grid>
                    }

                    <Grid item spacing={0}>
                        <FormControl sx={{ m: 0, minWidth: 187, }}>
                            <InputLabel id="demo-simple-select-label">One to One</InputLabel>
                            <Select
                                label="One to One"
                                labelId="demo-simple-select-label"
                                style={{ height: 45, paddingRight: 10 }}
                                value={fieldOneToOne}
                                onChange={(e) => {
                                    setFieldOneToOne(e.target.value)
                                }}
                                displayEmpty
                                IconComponent={() => { return (<ArrowDropDown />) }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {
                    (fieldType !== "relation") &&
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "2px" }}
                    >
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Can null</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={fieldCanNull}
                                    onChange={(e) => {
                                        setFieldCanNull(e.target.value)
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Unique</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={fieldUnique}
                                    onChange={(e) => {
                                        setFieldUnique(e.target.value)
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={submitFieldDetails}>Create</Button>
            </DialogActions>
        </Dialog>)
    }

    const handleFieldCreate = (details) => {

        let temp = [];

        for (let i = 0; i < config.properties[currentOpenFieldInputPopup].fields.length; i++) {
            temp.push(config.properties[currentOpenFieldInputPopup].fields[i].name)
        }

        if (temp.includes(details.name)) {
            toggleSnackbar();
        }
        else {

            console.log("fi", currentOpenFieldInputPopup);

            // const fieldTmp = {
            //     "name": fieldName,
            //     "type": fieldType,
            //     "default": fieldDefaultValue,
            //     "nullable": fieldCanNull,
            //     "relation": fieldRelationName,
            //     "one_to_one": fieldOneToOne,
            //     "unique": fieldUnique
            // }

            config.properties[currentOpenFieldInputPopup].fields.push(details)

            console.log(config);

            setOpenField(false);
        }
    }

    const formRowsFields = (data) => {
        let rows = [];
        for (let field of data) {
            rows.push({
                name: field.name,
                type: field.type,
                default: field.default,
                one_to_one: field.one_to_one ? "True" : "False",
                nullable: field.nullable ? "True" : "False",
                unique: field.unique ? "True" : "False",
            });
        }
        return rows;
    }


    const renderStepperElem = () => {
        switch (activeStep) {
            case 0:
                return (<React.Fragment>
                    <Grid
                        container
                        spacing={1}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        alignContent="stretch"
                        wrap="wrap"
                    >
                        <Grid item spacing={0}>
                            <MDInput
                                label="Project Name"
                                value={projectName}
                                onChange={(e) => {
                                    setProjectName(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        alignContent="stretch"
                        wrap="wrap"
                        style={{ marginTop: "5px" }}
                    >
                        <Grid item spacing={0} >
                            <MDInput
                                label="Short description about your project"
                                multiline
                                rows={5}
                                style={{ width: "400px" }}
                                value={projDescription}
                                onChange={(e) => {
                                    setProjectDescription(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>


                </React.Fragment>)
            case 1:
                return (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="wrap"
                        >
                            <Grid item spacing={0}>
                                <Typography variant="h6" color="initial">Choose Database</Typography>
                            </Grid>

                            <Grid item spacing={0}>
                                <Stack direction="row" spacing={1}>
                                    <Card style={{ height: "90px", border: "1px solid #008000" }}>
                                        <CardActionArea>
                                            <img width={90} src={require("assets/images/mongo_ic.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                    <Card style={{ height: "90px", background: "#d9d9d9" }}>
                                        <CardActionArea disabledx>
                                            <img width={90} src={require("assets/images/mysql_ic.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                </Stack>
                            </Grid>

                            <Grid item direction="row" spacing={3}>
                                <MDInput
                                    success={mongoConnectionStatus}
                                    label="MongoDB connection URL"
                                    value={mongoConnectionUrl}
                                    onChange={(e) => {
                                        setMongoConnectionUrl(e.target.value)
                                        setMongoConnectionStatus(false)

                                    }}
                                    style={{ width: "400px" }}

                                />

                                <MDButton onClick={checkConnect} variant="gradient" color="info" size="small">Check connection</MDButton>
                            </Grid>

                            <Grid item>
                                <MDInput

                                    label="Database Name"
                                    value={collectionName}
                                    onChange={(e) => {
                                        setCollectionName(e.target.value)
                                    }}
                                    style={{ width: "400px" }}

                                />


                            </Grid>

                            <Grid item spacing={0}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={enableUserAuth} onChange={() => {
                                        setEnableUserAuth(!enableUserAuth)
                                    }} />} label="Enable User Authentication" />
                                </FormGroup>
                            </Grid>

                            {enableUserAuth ? <Grid item spacing={0}>
                                <Stack direction="row" spacing={1}>
                                    <Card style={{ height: "90px", width: "90px", paddingTop: "10px", border: "1px solid #008000", textAlign: "center" }}>
                                        <CardActionArea>
                                            <img style={{ with: "70px", height: "70px" }} src={require("assets/images/code_ic.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                    <Card style={{ height: "90px", width: "90px", paddingTop: "10px", background: "#d9d9d9", textAlign: "center" }}>
                                        <CardActionArea disabledx>
                                            <img style={{ with: "70px", height: "70px" }} src={require("assets/images/firebase_ic.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                </Stack>

                                <Grid
                                    container
                                    spacing={1}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    alignContent="stretch"
                                    wrap="wrap"
                                    paddingTop={3}>
                                    <Grid item spacing={0}>
                                        <Typography variant="h6" color="initial">Admin User</Typography>
                                    </Grid>
                                    <Grid item spacing={0}>
                                        <MDInput label="Username" value={userAuthUsername}
                                            onChange={(e) => {
                                                setUserAuthUsername(e.target.value)

                                            }} />
                                    </Grid>
                                    <Grid item spacing={0}>
                                        <MDInput label="Password" value={userAuthPassword}
                                            onChange={(e) => {
                                                setUserAuthPassword(e.target.value)

                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid> : null}

                            <Grid item spacing={0}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={enableUserAuth} onChange={() => {
                                        setEnableDeviceManager(!enableDeviceManager)
                                    }} />} label="Enable Device Integration (IoT)" />
                                </FormGroup>
                            </Grid>

                            {enableDeviceManager ? <Grid item spacing={0}>
                                <Stack direction="row" spacing={1}>
                                    <Card style={{ height: "90px", width: "90px", paddingTop: "10px", border: "1px solid #008000", textAlign: "center" }}>
                                        <CardActionArea>
                                            <img style={{ with: "70px", height: "70px" }} src={require("assets/images/mqtt.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                    <Card style={{ height: "90px", width: "90px", paddingTop: "10px", background: "#d9d9d9", textAlign: "center" }}>
                                        <CardActionArea disabledx>
                                            <img style={{ with: "70px", height: "70px" }} src={require("assets/images/socketio.png")}></img>
                                        </CardActionArea>
                                    </Card>
                                </Stack>

                                <Grid
                                    container
                                    spacing={1}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    alignContent="stretch"
                                    wrap="wrap"
                                    paddingTop={3}>
                                    <Grid item spacing={0}>
                                        <Typography variant="h6" color="initial">Configurations</Typography>
                                    </Grid>
                                    <Grid item spacing={0}>
                                        <MDInput label="URL" value={mqttCreds.url}
                                            onChange={(e) => {
                                                setMqttCreds({
                                                    ...mqttCreds, url: e.target.value
                                                });
                                            }} />
                                    </Grid>
                                    <Grid item spacing={0}>
                                        <MDInput label="Username" value={mqttCreds.username}
                                            onChange={(e) => {
                                                setMqttCreds({
                                                    ...mqttCreds, username: e.target.value
                                                });
                                            }} />
                                    </Grid>
                                    <Grid item spacing={0}>
                                        <MDInput label="Password" value={mqttCreds.password}
                                            onChange={(e) => {
                                                setMqttCreds({
                                                    ...mqttCreds, password: e.target.value
                                                });
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid> : null}

                        </Grid>
                    </React.Fragment>)
            case 2:
                return (
                    <React.Fragment>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Entities
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={1.5} sx={{ ml: "auto" }}>
                                <MDButton onClick={handleClickOpenEntity} variant="outlined" color="info">
                                    Add&nbsp;
                                    <Icon>add</Icon>
                                </MDButton>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center">
                            {openField && <FieldDialogBox show={openField} toggleSnackbar={toggleSnackbar} opeSnackBar={opeSnackBar} title={currentOpenFieldInputItemName} createCallback={handleFieldCreate} handleClose={handleCloseField} getEntityNames={getEntitiesNameExcept} />}
                            <Dialog open={open} onClose={handleCloseEntity}>
                                <DialogTitle>Create Entity</DialogTitle>
                                <DialogContent>
                                    {/* <DialogContentText>
                                        To subscribe to this website, please enter your email address here. We
                                        will send updates occasionally.
                                    </DialogContentText> */}
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="entityName"
                                        label="Entity name"
                                        fullWidth
                                        variant="standard"
                                        value={entity}
                                        onChange={(e) => {
                                            setEntity(e.target.value);
                                            console.log(entity);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseEntity}>Cancel</Button>
                                    <Button onClick={handleEntityCreate}>Create</Button>
                                </DialogActions>
                            </Dialog>


                            {
                                (!config.properties.length) ? <Grid item xs={12}>
                                    <MDTypography variant="h3" fontWeight="medium" align="center">No Entities added</MDTypography>
                                </Grid> :
                                    config.properties.map((item, index) => {
                                        return <Grid item xs={12} paddingTop={1}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container item spacing={0}>
                                                        <Grid container direction="row" xs={10}>
                                                            <Typography variant="caption" sx={{ mt: 2, mb: 1 }}>Entity Name: </Typography>
                                                            <Typography style={{ marginTop: 9, marginLeft: 5 }} variant="h6">{item.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} >
                                                            <MDButton onClick={handleClickOpenField} variant="outlined" color="error">
                                                                <Icon>delete</Icon>
                                                            </MDButton>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container direction="column" spacing={0}>
                                                        <Grid item xs={12} md={6} lg={1.5} sx={{ ml: "auto" }} >
                                                            <MDButton onClick={() => handleClickOpenField(index, item)} variant="outlined" color="info">
                                                                Add Fields&nbsp;
                                                                <Icon>add</Icon>
                                                            </MDButton>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DataTable
                                                                entriesPerPage={false}
                                                                showTotalEntries={false}
                                                                table={{
                                                                    columns: [
                                                                        { Header: "Field name", accessor: "name", width: "30%" },
                                                                        { Header: "Type", accessor: "type", width: "15%" },
                                                                        { Header: "Default value", accessor: "default", width: "12%" },
                                                                        { Header: "One to One", accessor: "one_to_one", width: "12%" },
                                                                        { Header: "Can null", accessor: "nullable", width: "12%" },
                                                                        { Header: "Unique", accessor: "unique", width: "12%" },
                                                                    ],
                                                                    rows: formRowsFields(item.fields)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    })
                            }




                        </Grid>
                    </React.Fragment>)
            case steps.length:
                return (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - youre finished
                        </Typography>
                    </React.Fragment>
                );
            default:
                return (<React.Fragment>
                    <Typography variant="body1" color="initial">Project Step - {activeStep}</Typography>
                </React.Fragment>)
                break;
        }
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const getEntitiesNameExcept = (entity) => {
        let result = [];
        for (let ent of config.properties) {
            if (ent.name !== entity) {
                result.push({ name: ent.name });
            }
        }
        return result;
    }


    return (
        <DashboardLayout>

            {/* <DashboardNavbar /> */}
            <Box pt={6} pb={3}>
                <MDTypography variant="h4">{steps[activeStep]}</MDTypography>
                <React.Fragment>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step style={{ color: "black" }} key={label} {...stepProps}>
                                    <StepLabel color="black" {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </React.Fragment>
                <React.Fragment>
                    <Typography variant="caption" sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

                    {/* if use panna kekanum */}

                    {renderStepperElem()}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ?
                            (<React.Fragment>
                                <Button
                                    onClick={handleCreate}
                                >
                                    Create
                                </Button>
                            </React.Fragment>) :
                            (<React.Fragment>
                                <Button
                                    onClick={() => {
                                        handleNext();
                                    }}>
                                    Next
                                </Button>
                            </React.Fragment>)}

                    </Box>
                </React.Fragment>
                {
                    openSnackBar && <SnackBarField color={snackBarColor}
                        icon={snackBarIcon}
                        title={snackBarTitle}
                        content={snackBarContent}
                    />
                }
            </Box>

        </DashboardLayout>
    );
}

export default ProjectStepper;
