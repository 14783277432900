import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = 'https://deployments.appinmin.com';

var socket = null;



export const connect = (path, cbk) => {
    socket = io(`${URL}`, {
        query: {
            type: "logs-socket",
            process: path
        }
    });

    socket.on('connect', () => { cbk('connect', true) });
    socket.on('disconnect', () => { cbk('disconnect', false) });

    socket.on('line', (...args) => {
        console.log(args);
        cbk('line', args);
    });
}

export const disconnect = () => {
    socket.disconnect();
}