import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, Switch, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "context/auth.context";
import routes from "routes";
import { un_auth_routes } from "routes";
import { trackRoute } from "toolbox/analytics.helper";


export default function MainRoutes() {
  const { authenticated, userDoc } = useContext(AuthContext);
  const location = useLocation();
  console.log("auth state", authenticated, userDoc);
  useEffect(() => {
    // This callback will be executed on every route change
    console.log('Route has changed!');
    trackRoute(location.pathname);
  }, [location.pathname]); // Empty dependency array means it runs only on mount and unmount

  if (!authenticated) {
    return (
      <Routes>
        {un_auth_routes.map((route) => {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        })}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />;
      </Routes>
    )
  }
  return (
    <Routes>
      {routes.map((route) => {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      })}
      return <Route path="*" element={<Navigate to="/dashboard" />} />;
    </Routes>
  );
}

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ children, allowIfLoggedIn, allowIfNotLoggedIn }) => {
  const { authenticated } = useContext(AuthContext);
  console.log(authenticated);
  return (
    <Route
      render={(props) => {
        if (allowIfLoggedIn) {
          if (authenticated) {
            return children;
          } else {
            return (
              <Navigate
                to={{
                  pathname: "/login",
                }}
              />
            );
          }
        } else if (allowIfNotLoggedIn) {
          if (!authenticated) {
            return children;
          } else {
            return (
              <Navigate
                to={{
                  pathname: "/",
                }}
              />
            );
          }
        }
      }}
    />
  );
};
