import axios from "axios";
import moment from 'moment';


export const API_ENDPOINT = "https://api.appinmin.com/";
// export const API_ENDPOINT = "http://localhost:1337/"

var cancelToken;

export const getAxiosInstance = () => {
  var axiosInstance = axios.create({
    timeout: 1000 * 60
    // headers: {
    // 	'Content-Type': 'application/json',
    // 	'Accept': '*/*',
    // }
  });
  axiosInstance.defaults.baseURL = API_ENDPOINT;
  return axiosInstance;
};

var axiosInstance = getAxiosInstance();
axiosInstance.interceptors.request.use(
  function (config) {
    console.log(config.url);
    if (config.url === "aim/firebaseLogin") {
      return config;
    } else {
      let authToken = window.localStorage.getItem("jwt");
      console.log("Checking", authToken);
      console.log(authToken);
      config.headers["x-access-token"] = `${authToken}`;
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log("Status from response", response.status);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error.response.status);
  if (error.response.status == 401) {
    window.localStorage.clear();
    window.location.reload();
  }
  return Promise.reject(error);
});

export const fetchLogin = (idToken) => {
  return axiosInstance.post(
    "aim/firebaseLogin",
    {
      idToken: idToken,
    },
    {}
  );
};

export const fetchProjects = () => {
  return axiosInstance.get(`/projects/all`);
}

export const createProject = (projectName, projectDescription) => {
  return axiosInstance.post(
    "projects/new",
    {
      project_name: projectName,
      description: projectDescription,
    },
    {}
  );
};

export const deployProject = (projectID, configData) => {
  console.log(projectID, configData, "data check");
  return axiosInstance.post(
    "projects/deploy",
    {
      id: projectID,
      config: configData,
    },
    {}
  );
};

export const getProjectDetails = (projectID) => {
  return axiosInstance.get(`projects/details?project=${projectID}`);
};

export const getUsageDetails = (projectID) => {
  return axiosInstance.get(`projects/usage/week?project=${projectID}`);
};

export const getDashboardDetails = () => {
  return axiosInstance.get(`projects/dashboard`);
};

export const getProfileDetails = () => {
  return axiosInstance.get(`/profile/details`);
};


export const editProject = (id, projectName, projectDescription) => {
  return axiosInstance.put(
    `projects/edit/${id}`,
    {
      project_name: projectName,
      description: projectDescription,
    },
    {}
  );
};


export const editProjectGeneric = (id, details) => {
  return axiosInstance.put(
    `projects/edit/${id}`,
    details,
    {}
  );
};

export const checkConnection = (urlText) => {
  console.log(urlText, "test")
  return axiosInstance.post(
    "projects/verifymongo",
    {
      type: "URL",
      data: urlText,
    },
    {}
  );
};

export const changeProjectStatus = (projectID, command) => {
  return axiosInstance.post(
    "projects/command",
    {
      project: projectID,
      command: command
    },
    {}
  );
};

export const deleteProject = (projectID) => {
  return axiosInstance.delete(
    `projects/delete/${projectID}`,
    {}
  )
}

export const signupUser = (details) => {
  return axiosInstance.post(`/aim/signup`, details, {});
}

export const validateUser = (details) => {
  return axiosInstance.post(`/aim/validateUser`, details, {});
}

export const verifyMobile = (details) => {
  return axiosInstance.post(`/aim/mobileVerify`, details, {});
}

export const getDeployURL = (id) => {
  return axiosInstance.get(`/projects/deployment/details?project=${id}`);
}

export const postmanTemplate = (id) => {
  return axiosInstance.get(`/projects/deployment/postman?project=${id}`);
}