// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete } from "@mui/material";
// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MDSnackbar from "components/MDSnackbar";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


// Data
import { useEffect, useState, useRef } from "react";


import { fetchProjects } from "network/service";
import { connect, disconnect } from "network/socket";



function LogComponent() {

    const [connected, setConnected] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selProject, setSelProj] = useState(null);
    const [logs, setLogs] = useState([]);
    const logContainerRef = useRef(null);
    const [logConnectionStatus, setLogConnectionStatus] = useState(false);
    const toggleSnackbar = () => setLogConnectionStatus(!logConnectionStatus);
    const [snackbarColor, setSnackbarColor] = useState("");
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [snackbarContent, setSnackbarContent] = useState("")
;

    const listProjects = () => {
        fetchProjects().then(async (resp) => {
            let res = [];
            for (let rp of resp.data) {
                if (rp.deployment == 1) {
                    res.push({
                        id: rp.id,
                        name: rp.project_name,
                        status: rp.status
                    });
                }
            }
            setProjects(res);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        listProjects();
    }, [setProjects]);

    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logs]);

    const receivedCbk = (event, data) => {
        switch (event) {
            case 'connect':
                setConnected(true);
                setLogConnectionStatus(true);
                setSnackbarColor("success");
                setSnackbarTitle("Connected")
                setSnackbarContent(`Successfully connected to ${selProject.name} log`)
                break;
            case 'disconnect':
                setConnected(false);
                break;
            case 'line':
                console.log(data);
                addLog(data.join("\n"));
                break;
            default:
                break;
        }
    }

    const connectSocket = (project) => {
        connect(project, receivedCbk);
        console.log(project);
    }

    const disconnectSocket = () => {
        disconnect();
    }

    const handleConnection = (state, project) => {
        if (state) {
            connectSocket(project);
        }
        else {
            disconnectSocket();
        }
    }

    const addLog = (logText) => {
        setLogs((prevLogs) => [...prevLogs, logText]);
    };

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelProj(event.target.value);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDSnackbar
                color={snackbarColor}
                icon="notifications"
                title={snackbarTitle}
                content={snackbarContent}
                open={logConnectionStatus}
                close={toggleSnackbar}
            />

            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Logging
                                </MDTypography>
                            </MDBox>
                            {/* <Autocomplete
                                disableClearable
                                value={selProject}
                                options={projects}
                                onChange={(event, newValue) => {
                                    setSelProj(newValue);
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                                getOptionLabel={(option) => option.name}
                            />
                            <MDButton variant="gradient" color="info" style={{ margin: 5 }}
                                onClick={() => {
                                    handleConnection(!connected, selProject.id)
                                }}>
                                &nbsp;{connected ? 'Disconnect' : 'Connect'}
                            </MDButton> */}
                            <MDBox
                                component="li"
                                display="flex"
                                justifyContent="space-around"
                                alignItems="center"
                                py={1}
                                pr={1}
                                mb={1}>
                                <MDBox>
                                    <Autocomplete
                                        disableClearable
                                        value={selProject}
                                        options={projects}
                                        onChange={(event, newValue) => {
                                            setSelProj(newValue);
                                        }}
                                        size="small"
                                        sx={{ minWidth: "50rem" }}
                                        renderInput={(params) => <MDInput label={"Project"} {...params} />}
                                        getOptionLabel={(option) => option.name}
                                    />
                                </MDBox>
                                <MDBox>
                                    <MDButton variant="gradient" color="info" style={{ margin: 5 }}
                                        onClick={() => {
                                            handleConnection(!connected, selProject.id)
                                        }}>
                                        {/* <Icon sx={{ fontWeight: "bold" }}>add</Icon> */}
                                        &nbsp;{connected ? 'Disconnect' : 'Connect'}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={0}>
                                <Paper elevation={3} style={{ padding: '16px', minHeight: '200px', overflowY: 'auto', background: "#000" }}>
                                    <Typography variant="h4">Process Logs</Typography>
                                    <div ref={logContainerRef} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        {logs.map((log, index) => (
                                            <Typography key={index} variant="body2" color="#fff">
                                                {log}
                                            </Typography>
                                        ))}
                                    </div>
                                    {/* <button onClick={() => addLog(`Log entry ${logs.length + 1}`)}>Add Log</button> */}
                                </Paper>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default LogComponent;
