/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";


// React components
import MDBox from "components/MDBox";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";



function MobileCreation() {


    useEffect(() => {
        // Check if the flag is present in sessionStorage
        const shouldReload = sessionStorage.getItem('shouldReload');

        if (!shouldReload) {
            // Perform any actions you want to do once when the component mounts
            console.log('Main component mounted, reloading the page once.');

            // Reload the page
            sessionStorage.setItem('shouldReload', 'true');
            window.location.reload();
        }

        // Cleanup function (optional, but not needed in this case)
        return () => {
            // Any cleanup code if needed
            sessionStorage.removeItem('shouldReload');
        };
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <div data-snack-id="@ganapathy9599/appinminsample" data-snack-platform="web" data-snack-preview="true" data-snack-theme="dark" style={{ overflow: 'hidden', backgroundColor: "#fafafa", border: 1, height: 505, width: "100%" }}></div>
        </DashboardLayout>
    );
}

export default MobileCreation;
