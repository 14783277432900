// @mui material components
import Grid from "@mui/material/Grid";
import React from 'react';

import { useContext, useEffect, useState } from "react";
// React components
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDTypography from "components/MDTypography";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MDInput from "components/MDInput";
import Bill from "layouts/billing/components/Bill";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dashboard } from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Stack } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { getProjectDetails } from "network/service";
import MDBadge from "components/MDBadge";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate } from 'react-router-dom';
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { useLoading } from 'context/loading.context';
import Confirmation from "components/Confirmation";
import * as FileSaver from 'file-saver';
import Clipboard from 'clipboard';
import MDSnackbar from "components/MDSnackbar";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { getUsageDetails, deleteProject } from "network/service";
//Popups

import CustomFunctionPopup from "./components/CustomFunctionPopup";
import MqttFunctionPopup from "./components/MqttFunctionPopup";
import AddEntityPopup from "./components/AddEntityPopup";
import { deployProject, editProjectGeneric, getDeployURL, postmanTemplate } from "network/service";
import moment from "moment";



function Project(route) {
    const navigate = useNavigate();
    const { loading, showLoader, hideLoader } = useLoading();
    const { sales, tasks } = reportsLineChartData;
    const [functionpopup, setFunctionPopup] = useState({ open: false, details: null });
    const [addEntityPopup, setAddEntityPopup] = useState({ open: false, details: null });
    const [mqttfunctionpopup, setMqttFunctionPopup] = useState({ open: false, details: null });
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({});
    const location = useLocation();
    const [deployurl, seturl] = useState("");
    const [confirmation, setConfirmation] = useState({});
    const [notiDetails, setShowDetails] = useState({ show: false });
    const [apiCallsData, setApiCallsData] = useState({
        labels: [],
        datasets: { label: "API calls", data: [] },
    })

    const [transferData, setTransferData] = useState({
        labels: [],
        datasets: { label: "Data Transfer", data: [] },
    })

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeSB = () => setShowDetails({ ...notiDetails, show: false });

    const renderSB = (
        <MDSnackbar
          color={notiDetails.color}
          icon={notiDetails.icon}
          title={notiDetails.title}
          content={notiDetails.message}
          dateTime=""
          open={notiDetails.show}
          onClose={closeSB}
          close={closeSB}
          bgWhite
        />
      );

    useEffect(() => {
        console.log("sdsd", location);
        getDeployURL(location.state.id).then((resp) => {
            seturl(resp.data.url);
        }).catch((err) => {
            console.log(err);
        })
    }, [seturl]);

    const handleCopyClick = () => {
        const clipboard = new Clipboard('.copy-button', {
            text: () => deployurl,
        });

        // Manually trigger the click event to copy the text
        clipboard.on('success', (e) => {
            console.log('Text copied to clipboard:', e.text);
        });

        // Destroy the clipboard instance to avoid memory leaks
        clipboard.on('error', (e) => {
            console.error('Error copying to clipboard:', e.action);
        });

        clipboard.onClick({
            delegateTarget: document.querySelector('.copy-button'),
        });
    };

    const handleSaveClick = () => {
        showLoader();
        postmanTemplate(location.state.id).then((resp) => {
            console.log(resp.data);
            const jsonString = JSON.stringify(resp.data, null, 2);
            const blob = new Blob([jsonString], { type: 'application/json' });
            FileSaver.saveAs(blob, `${moment().unix()}.json`);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })

    };

    const changeName = (name, entity_ind) => {
        let temp = { ...data };
        if (temp.config.properties) {
            console.log(temp.config.properties[entity_ind]);
            temp.config.properties[entity_ind].name = name;
        }
        setData(temp);
        setAddEntityPopup({ open: true, details: { ...temp.config['properties'][entity_ind], entity_index: entity_ind } });
    }

    const addOpenEntity = () => {
        let temp = { ...data };
        let ind = 0;
        if (temp.config.properties) {
            ind = temp.config.properties.length;
            temp.config.properties.push({ name: "", fields: [] });
        }
        else {
            temp.config['properties'] = [{ name: "", fields: [] }]
        }
        setAddEntityPopup({ open: true, details: { name: "", fields: [], entity_index: ind } });
    }

    const addField = (dtls, entity_ind) => {
        let temp = { ...data };
        if (temp.config.properties) {
            console.log(temp.config.properties[entity_ind].fields);
            temp.config.properties[entity_ind].fields.push({ ...dtls });
        }
        else {
            temp.config['properties'] = [{ ...dtls }]
        }
        setData(temp);
        // setAddEntityPopup({ open: true, details: { ...temp.config['properties'][entity_ind],  entity_index: entity_ind} });
    }

    const updateProjectConfig = async () => {
        return new Promise((resolve, reject) => {
            setLoad(true);
            editProjectGeneric(location.state.id, { config: { ...data.config } }).then((resp) => {
                console.log(resp.data);
                setLoad(false);
                resolve(resp.data);
                setShowDetails({ color: "success", icon: "", title: "Entity Successfuly Added", message: "Please re-deploy the project for the changes to take effect in deployment", show: true });
            }).catch((err) => {
                console.log(err);
                setLoad(false);
                reject(err);
            })
        })

    }

    const deleteField = (fld_ind, entity_ind) => {
        let temp = { ...data };
        if (temp.config.properties) {
            temp.config.properties[entity_ind].fields.splice(fld_ind, 1);
        }
        else {
            temp.config['properties'] = [{ ...dtls }]
        }
        setData(temp);
        // setAddEntityPopup({ open: true, details: { ...temp.config['properties'][entity_ind],  entity_index: entity_ind} });
    }

    const updateEntity = (index, details, entity_ind) => {
        console.log("updating", index, details, entity_ind);
        let temp = { ...data };
        if (temp.config.properties) {
            temp.config.properties[entity_ind].fields[index] = { ...details };
        }
        setData(temp);
        // setAddEntityPopup({ open: true, details: { ...temp.config['properties'][entity_ind],  entity_index: entity_ind} });
    }

    const getEntitiesNameExcept = (entity) => {
        let result = [];
        for (let ent of data.config.properties) {
            if (ent.name !== entity) {
                result.push(ent.name);
            }
        }
        return result;
    }

    const ConvertDateToDay = (data) => {
        const dateParts = data.split('-');
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed
        const year = parseInt(dateParts[2], 10);
        const date = new Date(year, month, day);
        const options = { weekday: 'short' };
        const dayOfWeek = date.toLocaleDateString(undefined, options);
        return dayOfWeek;
    }

    const delete_Project = () => {

        deleteProject(location.state.id).then((resp) => {
            console.log("Delete response", resp);
            navigate("/projects")
        }).catch((err) => {
            console.log("Error while deleting project", err);
        })
    }

    const fetchDetails = () => {
        setLoad(true);
        showLoader();
        getProjectDetails(location.state.id).then(async (resp) => {
            console.log(resp.data);
            setData(resp.data)
            if (data != {}) {
                setLoad(false);
                hideLoader();
            }
            else {
                // setLoad(true);

            }

        }).catch((err) => {
            console.log(err);
            setLoad(false);
            hideLoader();
        });

        getUsageDetails(location.state.id).then(async (resp) => {
            let dl = [];
            let apiCalls = []
            let usageBytes = []
            let response = resp.data
            for (let data in response) {
                apiCalls.push(response[data].usagecalls ? response[data].usagecalls : 0);
                usageBytes.push(response[data].usagetransfers ? (response[data].usagetransfers / 1024).toFixed(2) : 0);
                dl.push(ConvertDateToDay(data))
            }
            dl.reverse();
            apiCalls.reverse();
            usageBytes.reverse();

            setApiCallsData({
                labels: dl,
                datasets: { label: "API Calls", data: apiCalls }
            });
            setTransferData({
                labels: dl,
                datasets: { label: "Data Transfer", data: usageBytes }
            });

        }).catch((err) => {
            console.log(err);
            setLoad(false);
            hideLoader();
        });

    }



    const formRowsFields = (entities) => {
        let rows = [];
        console.log("Iterating entities", entities);
        for (let entityData in entities) {
            rows.push({
                entity: `${entities[entityData].name}`,
                edit: <>
                    <MDButton variant="outlined" color="warning" iconOnly onClick={() => { console.log("Editing the Entities", entities[entityData]); setAddEntityPopup({ open: true, details: { ...entities[entityData], entity_index: entityData } }); }}>
                        <Icon>edit</Icon>
                    </MDButton>
                    <MDButton iconOnly onClick={() => {
                        showLoader();
                        console.log("Deleting an entity");
                        let temp = { ...data };
                        if (temp.config.properties) {
                            temp.config.properties.splice(entityData, 1);
                        }
                        setData(temp);
                        editProjectGeneric(location.state.id, { config: { ...temp.config } }).then((resp) => {
                            console.log(resp.data);
                            fetchDetails();
                        }).catch((err) => {
                            console.log(err);
                            fetchDetails();
                        })
                    }}>
                        <Icon color="red" style={{ color: 'red' }}>delete</Icon>
                    </MDButton></>,
            });
        }
        return rows;
    }

    const formRowsFunctions = (functions) => {
        let rows = [];
        for (let fnc in functions) {
            rows.push({
                name: functions[fnc].entity,
                path: functions[fnc].path,
                edit: <MDButton variant="outlined" color="warning" iconOnly onClick={() => { console.log("Editing the custom Function"); setFunctionPopup({ open: true, details: { ...functions[fnc], index: fnc } }); }}>
                    <Icon disable>edit</Icon>
                </MDButton>,
            });
        }
        return rows;
    }

    const formRowsMqttFunctions = (functions) => {
        let rows = [];
        for (let fnc in functions) {
            rows.push({
                topic: functions[fnc].topic,
                path: functions[fnc].method,
                edit: <MDButton variant="outlined" color="warning" iconOnly onClick={() => { console.log("Editing the custom Function"); setMqttFunctionPopup({ open: true, details: { ...functions[fnc], index: fnc } }); }}>
                    <Icon disable>edit</Icon>
                </MDButton>,
            });
        }
        return rows;
    }

    const createCustomMqttFunction = async (func) => {
        console.log("Updating the custom mqtt function");
        console.log(func);
        let new_config = data.config;
        if (func.index) {
            new_config["custom_functions_mqtt"][func.index] = func;
        }
        else {
            if (new_config.custom_functions_mqtt) {
                new_config["custom_functions_mqtt"] = [...new_config["custom_functions_mqtt"], func]
            }
            else {
                new_config["custom_functions_mqtt"] = [func];
            }
        }
        deployProject(location.state.id, new_config).then((resp) => {
            console.log(resp);
            setMqttFunctionPopup({ open: false, details: null });
            fetchDetails();
        }).catch((err) => {
            console.log(err);
        })
    }

    const createCustomFunction = async (func) => {
        console.log("Updating the custom function");
        console.log(func);
        let new_config = data.config;
        if (func.index) {
            new_config["custom_functions_rest"][func.index] = func;
        }
        else {
            if (new_config.custom_functions_rest) {
                new_config["custom_functions_rest"] = [...new_config["custom_functions_rest"], func]
            }
            else {
                new_config["custom_functions_rest"] = [func];
            }
        }
        deployProject(location.state.id, new_config).then((resp) => {
            console.log(resp);
            setFunctionPopup({ open: false, details: null });
            fetchDetails();
        }).catch((err) => {
            console.log(err);
        })
    }


    useEffect(() => {
        fetchDetails()
    }, [setData, setLoad]);

    return (
        // load ? <Stack alignItems="center">
        //     <CircularProgress />
        // </Stack> :
        <DashboardLayout>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Project delete confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, do you want to delete this project?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={delete_Project} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={0}>
                {mqttfunctionpopup.open && <MqttFunctionPopup isopen={mqttfunctionpopup.open} details={mqttfunctionpopup.details} entities={data.config ? data.config.properties : []} handleclose={() => { setMqttFunctionPopup({ open: false, details: null }) }} createFunction={createCustomMqttFunction} />}
                {functionpopup.open && <CustomFunctionPopup isopen={functionpopup.open} details={functionpopup.details} entities={data.config ? data.config.properties : []} handleclose={() => { setFunctionPopup({ open: false, details: null }) }} createFunction={createCustomFunction} />}
                {addEntityPopup.open && <AddEntityPopup
                    add={addField}
                    update={updateEntity}
                    delete={deleteField}
                    updateProject={updateProjectConfig}
                    isopen={addEntityPopup.open}
                    setName={changeName}
                    getEntityNames={getEntitiesNameExcept}
                    details={addEntityPopup.details}
                    handleclose={() => { setAddEntityPopup({ open: false, details: null }); fetchDetails() }}
                    createFunction={createCustomFunction} />}
                <Grid container xs={6} spacing={0} paddingRight={1} >
                    <Card sx={{ width: "100%", }} >
                        <CardContent sx={{ paddingTop: 2 }}>
                            <MDTypography gutterBottom variant="h5" fontWeight="medium" component="div">
                                {data.project_name}
                            </MDTypography>
                            <Stack paddingBottom={1} direction={"row"}>
                                <MDTypography variant="body2" fontWeight="medium" >Project ID:&nbsp; </MDTypography>
                                <MDTypography variant="body2" color="blue">{location.state.id} </MDTypography>
                            </Stack>
                            <Stack paddingBottom={1} direction={"row"} alignItems={"center"}>
                                <MDTypography variant="body2" fontWeight="medium" >Deployed at:&nbsp; </MDTypography>
                                <MDTypography variant="overline" color="blue">&nbsp;<a>{deployurl}</a> </MDTypography>
                                <MDButton className="copy-button" onClick={handleCopyClick} variant="contained" iconOnly><Icon>copy</Icon></MDButton>
                            </Stack>
                            <Stack direction={"row"}>
                                <MDTypography variant="body2" fontWeight="medium" >Status:&nbsp; </MDTypography>

                                <MDBadge badgeContent={(data.deployment == 1) ? "Deployed" : (data.deployment == 2) ? "Failed" : "Queued"} color={(data.deployment == 1) ? "success" : (data.deployment == 2) ? "error" : "info"} variant="gradient" size="md" />

                            </Stack>
                            <Stack direction={"row"} spacing={2} paddingTop={2}>
                                <MDButton variant="contained" color="warning" onClick={() => { handleSaveClick() }}>Postman Collection</MDButton>
                                <MDButton variant="contained" color="warning">Deploy</MDButton>
                                <MDButton variant="contained" color="error" onClick={() => { handleClickOpen() }}><DeleteIcon />  DELETE</MDButton>
                            </Stack>
                        </CardContent>

                    </Card>
                </Grid>

                <Grid container xs={6} direction={"row"} spacing={3} alignContent={"center"} paddingLeft={4} >
                    <Grid item xs={6}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                style={{ height: 400 }}
                                icon="api"
                                title="API Calls"
                                count={data.calls != undefined || "" ? data.calls : "0"}
                            // percentage={{
                            //     color: "success",
                            //     amount: "+10%",
                            //     label: "than yesterday",
                            // }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={6} >
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="buildcircle"
                                title="Data transfer"
                                count={data.transfer != undefined || 0 ? (data.transfer / 1024).toPrecision(2) + " kb" : "0 kb"}
                            // percentage={{
                            //     color: "error",
                            //     amount: "-3%",
                            //     label: "than yesterday",
                            // }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </Grid>
            {/* <MDBox mt={4.5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={6}>
                                <ReportsLineChart
                                    color="info"
                                    title="API calls"
                                    description={
                                        <>
                                            API calls for last 7 days
                                        </>
                                    }
                                    date="updated just now"
                                    chart={apiCallsData}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={6}>
                                <ReportsLineChart
                                    color="success"
                                    title="Data Transfer"
                                    description={
                                        <>
                                            Usage for last 7 days
                                        </>
                                    }
                                    date="updated just now"
                                    chart={transferData}
                                />
                            </MDBox>
                        </Grid>

                    </Grid>
                </MDBox> */}

            <Grid container direction={"row"} spacing={2} paddingTop={8}>
                <Grid item xs={6} spacing={0}>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <Grid container spacing={0} direction={"row"}>
                            <Grid item xs={10} spacing={0}>
                                <MDTypography variant="h6" color="white">
                                    Entities
                                </MDTypography>
                            </Grid>
                            <Grid item xs={2} spacing={0}>
                                <MDButton variant="outlined" color="white"
                                    onClick={() => { console.log("Adding the custom Function"); addOpenEntity(); }}
                                    iconOnly>
                                    <Icon>add</Icon>
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <DataTable
                        canSearch
                        table={{
                            columns: [
                                { Header: "Entity", accessor: "entity", width: "80%" },
                                { Header: "Edit", accessor: "edit", width: "20%", align: "right" },
                            ],
                            rows: formRowsFields(data.config ? data.config.properties : [])
                        }}
                    />
                </Grid>

                <Grid item xs={6} spacing={0}>
                    <MDBox

                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <Grid container spacing={0} direction={"row"}>
                            <Grid item xs={10} spacing={0}>
                                <MDTypography variant="h6" color="white">
                                    Custom functions
                                </MDTypography>
                            </Grid>
                            <Grid item xs={2} spacing={0}>
                                <MDButton variant="outlined" color="white" iconOnly
                                    onClick={() => { console.log("Adding the custom Function"); setFunctionPopup({ open: true, details: null }); }}>
                                    <Icon disable>add</Icon>
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <DataTable
                        canSearch
                        table={{
                            columns: [
                                { Header: "name", accessor: "name", width: "25%" },
                                { Header: "Path", accessor: "path" },
                                { Header: "Edit", accessor: "edit", width: "20%", align: "right" },
                            ],
                            rows: formRowsFunctions(data.config ? data.config.custom_functions_rest ? data.config.custom_functions_rest : [] : [])
                            // rows: [
                            //     {
                            //         name: "Intransit SMS",
                            //         path: "/trigger-transit",
                            //         edit: <MDButton variant="outlined" color="warning" iconOnly onClick={() => { console.log("Editing the custom Function"); setFunctionPopup({ open: true, details: {} }); }}>
                            //             <Icon disable>edit</Icon>
                            //         </MDButton>,
                            //     },
                            //     {
                            //         name: "Intransit SMS",
                            //         path: "/trigger-transit",
                            //         edit: <MDButton variant="outlined" color="warning" iconOnly>
                            //             <Icon>edit</Icon>
                            //         </MDButton>,
                            //     },
                            // ]
                        }}

                    />
                </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={2} paddingTop={8}>
                <Grid item xs={6} spacing={0}>
                    <MDBox

                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <Grid container spacing={0} direction={"row"}>
                            <Grid item xs={10} spacing={0}>
                                <MDTypography variant="h6" color="white">
                                    Device functions
                                </MDTypography>
                            </Grid>
                            <Grid item xs={2} spacing={0}>
                                <MDButton variant="outlined" color="white" iconOnly
                                    onClick={() => { console.log("Adding the custom Function"); setMqttFunctionPopup({ open: true, details: null }); }}>
                                    <Icon disable>add</Icon>
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <DataTable
                        canSearch
                        table={{
                            columns: [
                                { Header: "Topic", accessor: "topic", width: "25%" },
                                { Header: "Path", accessor: "path" },
                                { Header: "Edit", accessor: "edit", width: "20%", align: "right" },
                            ],
                            rows: formRowsMqttFunctions(data.config ? data.config.custom_functions_mqtt ? data.config.custom_functions_mqtt : [] : [])
                        }}

                    />
                </Grid>
            </Grid>
            {renderSB}
        </DashboardLayout>
    )
}

export default Project;
