// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import projectList from "./components/projectList";
import { useEffect, useState } from "react";

import logoXD from "assets/images/node.png";
import team1 from "assets/images/team-1.jpg";

import { fetchProjects, changeProjectStatus } from "network/service";
import { NavLink, useNavigate } from 'react-router-dom';
import Loader from "components/Loader";
import { useLoading } from 'context/loading.context';



function ProjectsComponent() {
    const { loading, showLoader, hideLoader } = useLoading();
    const navigate = useNavigate();
    const [load, setLoading] = useState (true);
    const [projects, setProjects] = useState([]);
    
    useEffect(() => {
        const listProjects1 = () => {
            setLoading (true);
            showLoader();
            fetchProjects().then(async(resp) => {
                setLoading (false);
                hideLoader();
                console.log(resp.data);
                let res = [];
                for (let rp of resp.data) {
                    res.push({
                        name: rp.project_name,
                        members: [
                            [team1, "Habibi Abi"]
                        ],
                        status: rp.status,
                        deployment: rp.deployment,
                        id: rp.id,
                        logo: logoXD,
                        budget: "$12",
                        values: 10
                    });
                }
                setProjects(res);
                
            }).catch((err) => {
                setLoading (false);
                hideLoader();
                console.log(err);
            });
        }
        listProjects1();
    }, [setLoading, setProjects]);


    const listProjects = () => {
        setLoading (true);
        fetchProjects().then(async(resp) => {
            setLoading (false);
            console.log(resp.data);
            let res = [];
            for (let rp of resp.data) {
                res.push({
                    name: rp.project_name,
                    members: [
                        [team1, "Habibi Abi"]
                    ],
                    status: rp.deployment,
                    deployment: rp.deployment,
                    id: rp.id,
                    logo: logoXD,
                    budget: "$12",
                    values: 10
                });
            }
            setProjects(res);
            
        }).catch((err) => {
            setLoading (false);
            console.log(err);
        });
    }

    const editProject = (details) => {
        console.log("Editing the projects", details);
    }
    
    const addProject = () => {
        navigate("/create-project");
        console.log("Adding new Project");
    }

    const setDeployState = (state, project) => {
        console.log(state);
        if (state)
        {
            changeProjectStatus(project, 'restart').then((resp)=>{
                console.log("Command sent for starting the project deployment");
                listProjects();
            }).catch((err)=>{
                console.log(err);
            })
        }
        else
        {
            changeProjectStatus(project, 'stop').then((resp)=>{
                console.log("Command sent for stoping the project deployment");
                listProjects();
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    

    return (
        // loading ? (<Loader/>) :
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Projects Table
                                </MDTypography>
                            </MDBox>
                            <MDBox
                                mx={2}
                                mt={0}
                                py={2}
                                px={0}
                                borderRadius="lg"
                                alignSelf="flex-end"
                            >
                                {/* <MDInput label="Search" style={{ margin: 5 }} /> */}
                                <MDButton variant="gradient" color="info" style={{ margin: 5 }}
                                    onClick={() => {
                                        addProject();
                                    }}>
                                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                    &nbsp;add new project
                                </MDButton>
                            </MDBox>
                            <MDBox pt={0}>
                                <DataTable
                                    table={projectList(projects, editProject, setDeployState)}
                                    isSorted={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                    entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default ProjectsComponent;
