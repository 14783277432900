/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import GoogleIcon from "@mui/icons-material/Google";
import LinearProgress from '@mui/material/LinearProgress';

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { NavLink, useNavigate } from 'react-router-dom';
import { auth } from "../../../firebase";
import { createUserWithEmailAndPassword, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { signupUser } from "network/service";
import { AuthContext } from "../../../context/auth.context"

// Images
import bgImage from "assets/images/web_ai.jpg";
import { useState, useContext } from "react";
import { Password } from "@mui/icons-material";
import BasicLayout from "layouts/authentication/components/BasicLayout";


function Cover() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState("");
  const [mobile, setMobile] = useState("");
  const [profession, setProfession] = useState("");
  const [userNmae, setUsername] = useState("");
  const [notiDetails, setShowDetails] = useState({ show: false });

  const { setLogin, setUserDoc } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const closeSB = () => setShowDetails({ ...notiDetails, show: false });

  const renderSB = (
    <MDSnackbar
      color={notiDetails.color}
      icon={notiDetails.icon}
      title={notiDetails.title}
      content={notiDetails.message}
      dateTime=""
      open={notiDetails.show}
      onClose={closeSB}
      close={closeSB}
      bgWhite
    />
  );


  // const signInWithGoogle = async () => {

  //   const auth = getAuth();
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(auth, provider).then((result) => {
  //     setLoading(true);
  //     // This gives you a Google Access Token. You can use it to access the Google API.
  //     const credential = GoogleAuthProvider.credentialFromResult(result);
  //     const token = credential.accessToken;
  //     // The signed-in user info.
  //     const user = result.user;
  //     // IdP data available using getAdditionalUserInfo(result)
  //     user.getIdToken().then(function (idToken) {
  //       console.log(idToken);
  //       fetchLogin(idToken)
  //         .then((resp) => {
  //           console.log(resp.data);
  //           window.localStorage.setItem("jwt", resp.data.token);
  //           setLogin(resp.data.token);
  //           setUserDoc(resp.data);
  //           console.log("User Logged in", window.localStorage.getItem("jwt"));
  //           setLoading(false);
  //           navigate("/dashboard");
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           console.log(err);
  //         });
  //     });
  //   }).catch((error) => {
  //     setLoading(false);
  //     console.log(error);
  //     // Handle Errors here.
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     console.log(errorCode, errorMessage);
  //     // The email of the user's account used.
  //     const email = error.customData.email;
  //     // The AuthCredential type that was used.
  //     const credential = GoogleAuthProvider.credentialFromError(error);
  //     console.log(email);
  //     console.log(credential);
  //   });
  // }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (mobile.length != 10) {
      setShowDetails({ color: "error", icon: "", title: "Signup Failed", message: "Invalid mobile number", show: true });
    }
    else if (password != confirmPass) {
      setShowDetails({ color: "error", icon: "", title: "Signup Failed", message: "Password and Confirm Password does not match", show: true });
    }
    else if ((mobile.length == 10) &&
      (password === confirmPass)) {
      setLoading(true);
      signupUser({
        name: userNmae,
        password: password,
        mobile: `+91${mobile}`,
        email: email,
        profession: profession
      }).then((resp) => {
        console.log(resp.data);
        setShowDetails({ color: "success", icon: "", title: "Signup Success", message: `You can Sign-In now.`, show: true });
        setLoading(false);
        navigate("/authentication/sign-in");
      }).catch((err) => {
        setLoading(false);
        console.log(err);
        setShowDetails({ color: "error", icon: "", title: "Signup Failed", message: err.response.data.errors[0]["message"], show: true });
      })
    }
  }

  // const onSubmit = async (e) => {
  //   setLoading(true);
  //   e.preventDefault()
  //   console.log(email, password);
  //   // let uc = await createUserWithEmailAndPassword(auth, email, password);
  //   createUserWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       // Signed in
  //       const user = userCredential.user;
  //       console.log(user);
  //       setLoading(false);
  //       navigate("/dashboard")

  //       //Get access token
  //       let jwtToken = firebase.auth().onAuthStateChanged(function (user) {
  //         if (user) {
  //           user.getIdToken().then(function (idToken) {  // <------ Check this line
  //             alert(idToken); // It shows the Firebase token now
  //             return idToken;
  //           });
  //         }
  //       });
  //       console.log(jwtToken);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       alert(error.code)
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(errorCode, errorMessage);
  //     });
  // }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your details to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Name" variant="standard" fullWidth
                value={userNmae}
                onChange={(event) => {
                  setUsername(event.target.value);
                }} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(text) => {
                  setEmail(text.target.value)
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                value={password}
                onChange={(text) => {
                  setPassword(text.target.value)
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                value={confirmPass}
                onChange={(text) => {
                  setConfirmPass(text.target.value)
                }}
                error={(password !== confirmPass)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Mobile"
                variant="standard"
                fullWidth
                value={mobile}
                onChange={(text) => {
                  setMobile(text.target.value)
                }}
                error={(mobile.length != 10)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label="Profession"
                variant="standard"
                fullWidth
                value={profession}
                onChange={(text) => {
                  setProfession(text.target.value)
                }}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton disabled={isLoading} variant="gradient" color="info" fullWidth onClick={onSubmit}>
                sign up
              </MDButton>
            </MDBox>
            {/* <MDBox mt={4} mb={1}>
              <MDButton disabled={isLoading} variant="outlined" color="info" startIcon={<GoogleIcon color="inherit" />} onClick={() => {signInWithGoogle()}} fullWidth>
                Google
              </MDButton>
            </MDBox> */}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          {isLoading && <MDBox style={{ overflow: 'hidden' }}><LinearProgress /></MDBox>}
          {renderSB}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
