/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Icon } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";


import Editor from '@monaco-editor/react';
import moment from "moment";
// import {} from "service/network";

var default_function = `return (async () => 
{
    try
    {
        /** User code goes here **/
        /** Context is in the variable ctx eg: ctx.request.body**/
        /** Core Controller is accessible via core_api object **/
        console.log("Entering Custom function...");
        ctx.response.send({message: "Successfully entered the custom function"});
    }
    catch (err)
    {
        console.log(err); ctx.response.status(500).json(err);
    }
})()`;

var options = [
    {
        name: "Yes",
        value: true
    },
    {
        name: "No",
        value: false
    }
];


var methods = [
    "get",
    'put',
    "post",
    'delete'
];


export default function AddEntityPopup(props) {

    const [entity, setEntity] = useState(null);
    const [method, setMethod] = useState(null);
    const [name, setName] = useState("");
    const [path, setPath] = useState("");
    const [override, setOverride] = useState(null);
    const [entIndex, setEntIndex] = useState(0);
    const [notiDetails, setShowDetails] = useState({ show: false });
    const [funct, setFunct] = useState(default_function);
    const [fields, setFields] = useState([]);
    const [fieldName, setFieldName] = useState("");
    const [fieldType, setFieldType] = useState("");
    const [fieldRelation, setFieldRelation] = useState(null);
    const [fieldDefaultValue, setFieldDefaultValue] = useState();
    const [fieldOneToOne, setFieldOneToOne] = useState();
    const [fieldCanNull, setFieldCanNull] = useState();
    const [fieldUnique, setFieldUnique] = useState();
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ name: "", nullable: false, default: "", unique: false, type: 'string', one_to_one: false, entity: "", relation: "" });


    const closeSB = () => setShowDetails({ ...notiDetails, show: false });


    const renderSB = (
        <MDSnackbar
            color={notiDetails.color}
            icon={notiDetails.icon}
            title={notiDetails.title}
            content={notiDetails.message}
            dateTime=""
            open={notiDetails.show}
            onClose={closeSB}
            close={closeSB}
            bgWhite
        />
    );


    useEffect(() => {
        console.log("modal useEffect", props.details);
        if (props.details) {
            //Editing Dialog
            console.log("Details found for edit", props.details);
            setEntity(props.details);
            setName(props.details.name);
            setFields(props.details.fields);
            setEntIndex(props.details.entity_index);
        }
    }, [setEntity, setName, props.details, props.details.name]);

    const createFunction = () => {
        let temp = {
            "entity": entity.name,
            "method": method,
            "path": `/${path}`,
            "override": override.value,
            "authenticate": true,
            "function": funct,
            "index": props.details?.index ? props.details.index : null
        }
        props.createFunction(temp);
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            width="70%"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <DialogTitle>Entity</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="entityName"
                    label="Entity name"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => {
                        props.setName(e.target.value, entIndex);
                        console.log(name);
                    }}
                />
                <MDTypography>Add Field</MDTypography>
                <Grid
                    container
                    spacing={1}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    alignContent="stretch"
                    wrap="wrap"
                    style={{ marginTop: "8px" }}
                >
                    {/* <DialogTitle>Input fields for "{entity}" Entity:</DialogTitle> */}
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "2px" }}
                    >
                        <Grid item spacing={0}>
                            <MDInput
                                label="Field Name"
                                value={newField.name}
                                onChange={(e) => {
                                    setNewField({
                                        ...newField,
                                        name: e.target.value
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    label="Type"
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={newField.type}
                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            type: e.target.value
                                        });
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={"string"}>String</MenuItem>
                                    <MenuItem value={"number"}>Numeric</MenuItem>
                                    {/* <MenuItem value={2}>Timestamp</MenuItem> */}
                                    <MenuItem value={"relation"}>Relation</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {(newField.type !== "relation") &&
                            <Grid item spacing={0}>
                                <MDInput
                                    value={newField.default}
                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            default: e.target.value
                                        });
                                    }}
                                    label="Default Value"
                                />
                            </Grid>
                        }

                        {(newField.type === "relation") &&
                            <Grid item spacing={0} style={{ minWidth: 187 }}>
                                <Autocomplete
                                    label="Entity"
                                    disableClearable
                                    value={newField.relation}
                                    options={props.getEntityNames(name)}
                                    onChange={(event, newValue) => {
                                        setNewField({
                                            ...newField,
                                            relation: newValue
                                        });
                                    }}
                                    size="small"
                                    // sx={{ width: "5rem" }}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <MDInput label="Entity" {...params} />}
                                />
                            </Grid>
                        }

                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">One to One</InputLabel>
                                <Select
                                    label="One to One"
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={newField.one_to_one}
                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            one_to_one: e.target.value
                                        });
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Can null</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={newField.nullable}
                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            nullable: e.target.value
                                        });
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Unique</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={newField.unique}
                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            unique: e.target.value
                                        });
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item spacing={0}>
                            <MDButton variant="outlined" color="success" onClick={() => { props.add({ ...newField }, entIndex) }}><Icon sx={{ fontWeight: "bold" }}>add</Icon>Add Field</MDButton>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid
                    container
                    direction="row"
                    spacing={1}
                    style={{ marginTop: "2px" }}
                >
                    {(fieldType !== "relation") &&
                        <Grid item spacing={0}>
                            <MDInput
                                value={fieldDefaultValue}
                                onChange={(e) => {
                                    setFieldDefaultValue(e.target.value)
                                }}
                                label="Default Value"
                            />
                        </Grid>
                    }

                    {(fieldType === "relation") &&
                        <Grid item spacing={0}>
                            <Autocomplete
                                label="Entity"
                                disableClearable
                                value={fieldRelation}
                                options={props.getEntityNames(props.title)}
                                onChange={(event, newValue) => {
                                    setFieldRelation(newValue);
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <MDInput label="Entity" {...params} />}
                            />
                        </Grid>
                    }

                    <Grid item spacing={0}>
                        <FormControl sx={{ m: 0, minWidth: 187, }}>
                            <InputLabel id="demo-simple-select-label">One to One</InputLabel>
                            <Select
                                label="One to One"
                                labelId="demo-simple-select-label"
                                style={{ height: 45, paddingRight: 10 }}
                                value={fieldOneToOne}
                                onChange={(e) => {
                                    setFieldOneToOne(e.target.value)
                                }}
                                displayEmpty
                                IconComponent={() => { return (<ArrowDropDown />) }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> */}

                {/* {
                    (fieldType !== "relation") &&
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "2px" }}
                    >
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Can null</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={fieldCanNull}
                                    onChange={(e) => {
                                        setFieldCanNull(e.target.value)
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item spacing={0}>
                            <FormControl sx={{ m: 0, minWidth: 187, }}>
                                <InputLabel id="demo-simple-select-label">Unique</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    style={{ height: 45, paddingRight: 10 }}
                                    value={fieldUnique}
                                    onChange={(e) => {
                                        setFieldUnique(e.target.value)
                                    }}
                                    displayEmpty
                                    IconComponent={() => { return (<ArrowDropDown />) }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>} */}
                <MDTypography>Fields</MDTypography>
                {
                    fields.map((fld, indx) =>
                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            wrap="wrap"
                            style={{ marginTop: "8px" }}
                        >
                            {/* <DialogTitle>Input fields for "{entity}" Entity:</DialogTitle> */}
                            <Grid
                                container
                                direction="row"
                                spacing={1}
                                style={{ marginTop: "2px" }}
                            >
                                <Grid item spacing={0}>
                                    <MDInput
                                        label="Field Name"
                                        value={fld.name}
                                        onChange={(e) => {
                                            // setName(e.target.value)
                                            props.update(indx, {
                                                ...fld,
                                                name: e.target.value
                                            }, entIndex);
                                        }}
                                    />
                                </Grid>
                                <Grid item spacing={0}>
                                    <FormControl sx={{ m: 0, minWidth: 187, }}>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            label="Type"
                                            labelId="demo-simple-select-label"
                                            style={{ height: 45, paddingRight: 10 }}
                                            value={fld.type}
                                            onChange={(e) => {
                                                // setFieldType(e.target.value)
                                                props.update(indx, {
                                                    ...fld,
                                                    type: e.target.value
                                                }, entIndex);
                                            }}
                                            displayEmpty
                                            IconComponent={() => { return (<ArrowDropDown />) }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={"string"}>String</MenuItem>
                                            <MenuItem value={"number"}>Numeric</MenuItem>
                                            {/* <MenuItem value={2}>Timestamp</MenuItem> */}
                                            <MenuItem value={"relation"}>Relation</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(fld.type !== "relation") &&
                                    <Grid item spacing={0}>
                                        <MDInput
                                            value={fld.default ? fld.default : ""}
                                            onChange={(e) => {
                                                // setFieldDefaultValue(e.target.value)
                                                props.update(indx, {
                                                    ...fld,
                                                    default: e.target.value
                                                }, entIndex);
                                            }}
                                            label="Default Value"
                                        />
                                    </Grid>
                                }

                                {(fld.type === "relation") &&
                                    <Grid item spacing={0} style={{ minWidth: 187 }}>
                                        <Autocomplete
                                            label="Entity"
                                            disableClearable
                                            value={fld.relation}
                                            options={props.getEntityNames(name)}
                                            onChange={(event, newValue) => {
                                                // setFieldRelation(newValue);
                                                props.update(indx, {
                                                    ...fld,
                                                    relation: newValue
                                                }, entIndex);
                                            }}
                                            size="small"
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => <MDInput label="Entity" {...params} />}
                                        />
                                    </Grid>
                                }

                                <Grid item spacing={0}>
                                    <FormControl sx={{ m: 0, minWidth: 187, }}>
                                        <InputLabel id="demo-simple-select-label">One to One</InputLabel>
                                        <Select
                                            label="One to One"
                                            labelId="demo-simple-select-label"
                                            style={{ height: 45, paddingRight: 10 }}
                                            value={fld.one_to_one}
                                            onChange={(e) => {
                                                // setFieldOneToOne(e.target.value)
                                                props.update(indx, {
                                                    ...fld,
                                                    one_to_one: e.target.value
                                                }, entIndex);
                                            }}
                                            displayEmpty
                                            IconComponent={() => { return (<ArrowDropDown />) }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item spacing={0}>
                                    <FormControl sx={{ m: 0, minWidth: 187, }}>
                                        <InputLabel id="demo-simple-select-label">Can null</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            style={{ height: 45, paddingRight: 10 }}
                                            value={fld.nullable}
                                            onChange={(e) => {
                                                // setFieldCanNull(e.target.value)
                                                props.update(indx, {
                                                    ...fld,
                                                    nullable: e.target.value
                                                }, entIndex);
                                            }}
                                            displayEmpty
                                            IconComponent={() => { return (<ArrowDropDown />) }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item spacing={0}>
                                    <FormControl sx={{ m: 0, minWidth: 187, }}>
                                        <InputLabel id="demo-simple-select-label">Unique</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            style={{ height: 45, paddingRight: 10 }}
                                            value={fld.unique}
                                            onChange={(e) => {
                                                // setFieldUnique(e.target.value)
                                                props.update(indx, {
                                                    ...fld,
                                                    unique: e.target.value
                                                }, entIndex);
                                            }}
                                            displayEmpty
                                            IconComponent={() => { return (<ArrowDropDown />) }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item spacing={0}>
                                    <MDButton variant="outlined" color="error" iconOnly onClick={() => {
                                        props.delete(indx, entIndex);
                                    }}> <Icon sx={{ fontWeight: "bold" }}>delete</Icon></MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={props.handleclose}>Cancel</Button>
                <Button disabled={loading} onClick={() => {
                    setLoading(true);
                    props.updateProject().then((resp) => {
                        setLoading(false);
                        console.log("Updated the project details in the system");
                        props.handleclose();
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                        setShowDetails({ color: "error", icon: "", title: "Updation Failed", message: "Failed to update the project details", show: true });
                    })
                }}>Save</Button>
            </DialogActions>
            {renderSB}
        </Dialog>

    )
};
