/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Icon } from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";


import Editor from '@monaco-editor/react';
import moment from "moment";
// import {} from "service/network";

var default_function = `return (async () => 
{
    try
    {
        /** User code goes here **/
        /** Context is in the variable ctx eg: ctx.request.body**/
        /** Core Controller is accessible via core_api object **/
        console.log("Entering Custom function...");
        ctx.response.send({message: "Successfully entered the custom function"});
    }
    catch (err)
    {
        console.log(err); ctx.response.status(500).json(err);
    }
})()`;

var options = [
    {
        name: "Yes",
        value: true
    },
    {
        name: "No",
        value: false
    }
];


var methods = [
    "get",
    'put',
    "post",
    'delete'
];


export default function FunctionPopup(props) {

    const [entity, setEntity] = useState (null);
    const [method, setMethod] = useState (null);
    const [path, setPath] = useState ("");
    const [override, setOverride] = useState (null);
    const [notiDetails, setShowDetails] = useState({ show: false });
    const [funct, setFunct] = useState(default_function);

    const closeSB = () => setShowDetails({ ...notiDetails, show: false });


    const renderSB = (
        <MDSnackbar
            color={notiDetails.color}
            icon={notiDetails.icon}
            title={notiDetails.title}
            content={notiDetails.message}
            dateTime=""
            open={notiDetails.show}
            onClose={closeSB}
            close={closeSB}
            bgWhite
        />
    );


    useEffect(() => {
        console.log("modal useEffect", props.details);
        if (props.details) {
            //Editing Dialog
            console.log("Details found for edit", props.details);
            for (let ent of props.entities)
            {
                if (ent.name === props.details.entity)
                {
                    setEntity(ent);
                    break;
                }
            }
            setOverride (props.details.override ? options[0] : options[1]);
            setMethod (props.details.method);
            setPath (props.details.path.slice(1));
            setFunct (props.details.function);
        }
    }, [setOverride, setEntity, setMethod, setPath, setFunct]);

    const createFunction = () => {
        let temp = {
            "entity":entity.name,
            "method":method,
            "path":`/${path}`,
            "override":override.value,
            "authenticate":true,
            "function": funct,
            "index": props.details?.index ? props.details.index : null
        }
        props.createFunction (temp);
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <DialogContent style={{ width: 1500, height: 'auto' }}>
                <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    color="dark"
                >
                    {"Custom Functions"}
                </MDTypography>
                <Grid
                    container xs={12}
                    direction='row'
                    width={"100%"}
                    className="form-wrapper"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: "space-around", margin: 10 }}
                >
                    <Grid item xs={5} className="form-content">
                        <Autocomplete
                            disableClearable
                            getOptionLabel={(data) => {
                                return data.name;
                            }}
                            onChange={(oldval, newVal) => {
                                if (newVal) {
                                    setEntity(newVal);
                                }
                            }}
                            id="controllable-states-demo"
                            options={props.entities}
                            value={entity}
                            renderInput={(params) => <TextField {...params} label="Entity" />}
                        />
                    </Grid>
                    <Grid item xs={5} className="form-content">
                        <Autocomplete
                            disableClearable
                            // getOptionLabel={(data) => {
                            //     return data.name;
                            // }}
                            onChange={(oldval, newVal) => {
                                if (newVal) {
                                    setMethod(newVal);
                                }
                            }}
                            id="controllable-states-demo"
                            options={methods}
                            value={method}
                            renderInput={(params) => <TextField {...params} label="Method" />}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container xs={12}
                    direction='row'
                    width={"100%"}
                    className="form-wrapper"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: "space-around", margin: 10 }}
                >
                    <Grid item xs={5} className="form-content">
                        <MDInput
                            sx={{ width: "100%" }}
                            name="path"
                            label="Path"
                            value={path}
                            onChange={(event) => {
                                setPath(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} className="form-content">
                        <Autocomplete
                            disableClearable
                            getOptionLabel={(data) => {
                                return data.name;
                            }}
                            onChange={(oldval, newVal) => {
                                if (newVal) {
                                    setOverride(newVal);
                                }
                            }}
                            id="controllable-states-demo"
                            options={options}
                            value={override}
                            renderInput={(params) => <TextField {...params} label="Override" />}
                        />
                    </Grid>
                </Grid>
                <Editor theme="vs-dark" height="60vh" marginTop="5vh" defaultLanguage="javascript" defaultValue={funct}
                onChange={(value, event)=>{setFunct(value);}}/>
                <Grid
                    container xs={12}
                    direction='row'
                    width={"100%"}
                    className="form-wrapper"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", marginTop: 20 }}
                >
                    <MDButton variant="gradient" color="success" style={{ margin: 5 }}
                        onClick={() => {
                            createFunction ();
                        }}>
                        <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                        &nbsp;save
                    </MDButton>
                </Grid>
            </DialogContent>
        </Dialog >
    )
};
